<template>
  <div>
    <statistic-card
      v-bind="$attrs"
      :showYear="null"
      :elevation="0"
      class="kibmd-tab2__card"
      :isOpenDetail="false"
      @itemClick="itemSelect = $event"
    ></statistic-card>

    <kibmd-partition-table
      v-if="data.length > 0"
      class="kibmd-tab2__table"
      :data="data"
      :statisticIndicator="this.$attrs.statisticIndicator"
    ></kibmd-partition-table>
  </div>
</template>
<script>
export default {
  components: {
    statisticCard: () =>
      import("../../../../views/statisticIndicator/statisticCard.vue"),
    kibmdPartitionTable: () => import("./kibmdPartitionTable.vue"),
  },
  data() {
    return {
      data: [],
      itemSelect: null,
    };
  },
  watch: {
    "$attrs.statisticIndicator": {
      handler(val) {
        this.init();
      },
    },
    itemSelect(val) {
      this.data = [];
      for (let i = 0; i < val.Values.length; i++) {
        const element = val.Values[i];
        this.data.push({
          Id: element.Id,
          Name: element?.Object?.Name || element?.OwnerName,
          Date: this.$options.filters.DateOnly(element.Date),
          Value: element.Value,
        });
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let t = this.$attrs?.statisticIndicator?.Groups?.length;
      const t2 = this.$attrs?.statisticIndicator?.Groups.findLastIndex(
        (e) => e.Value !== null
      );
      if (t2 !== -1) t = t2 + 1;

      if (t) {
        this.itemSelect = this.$attrs?.statisticIndicator?.Groups[t - 1];
      } else {
        this.itemSelect = null;
      }
    },
  },
};
</script>
